import axios from "../services/api";
import jwt from "jwt-decode";
import React, { createContext, useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";

interface IAuthContext {
  user: IUserProps | undefined;
  logged: boolean;
  signIn(email: string, password: string): void;
  logout(): void;
  loading: boolean;
}

interface IUserProps {
  name: string;
  email: string;
  acess_level: string;
}

const AuthContext = createContext<IAuthContext>({} as IAuthContext);

const AuthProvider: React.FC = ({ children }) => {
  const [logged, setLogged] = useState<boolean>(false);
  const [user, setUser] = useState<IUserProps>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const token = localStorage.getItem("cestabasicabrasil@token");

    if (!token) {
      toast.warning("Você precisa fazer login", {
        position: "top-right",
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
      setLoading(false);
      setLogged(false);
      return;
    }

    async function fetchData() {
      try {
        const { email, name, acess_level } = jwt(token as string) as IUserProps;

        setUser({
          email,
          name,
          acess_level,
        });

        setLoading(false);
        setLogged(true);
      } catch (e) {
        console.log(e);
        toast.warning("Você precisa fazer login", {
          position: "top-right",
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        setLoading(false);
        setLogged(false);
      }
    }
    fetchData();
  }, []);

  const signIn = async (email: string, password: string) => {
    try {
      const response = await axios.post("/login", {
        email,
        password,
      });

      const token = response.data;

      localStorage.setItem("cestabasicabrasil@token", token);
      window.location.reload();
    } catch (err: any) {
      if (!err.response) {
        toast.warning("Não foi possivel se conectar ao servidor", {
          position: "top-right",
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });

        return;
      }

      toast.error("E-mail ou senha incorreto", {
        position: "top-right",
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
  };

  const logout = () => {
    localStorage.removeItem("cestabasicabrasil@token");
    window.location.reload();
  };

  return (
    <AuthContext.Provider value={{ logged, user, loading, signIn, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): IAuthContext {
  const context = useContext(AuthContext);

  return context;
}

export { AuthProvider, useAuth };
