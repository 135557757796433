import { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";

import { Layout } from "../components/Layout";
import { Loading } from "../components/Loading";
import { useAuth } from "../hooks/auth";
import { NotFound } from "../pages/NotFound";

const Tickets = lazy(() => import("../pages/Tickets"));
const Employee = lazy(() => import("../pages/Employee"));
const Analysis = lazy(() => import("../pages/Analysis"));
const Movement = lazy(() => import("../pages/Movement"));
const NewTicket = lazy(() => import("../pages/NewTicket"));
const Enterprises = lazy(() => import("../pages/Enterprises"));
const AnalysisData = lazy(() => import("../pages/AnalysisData"));
const AnalysisDates = lazy(() => import("../pages/AnalysisDates"));
const EmployeeTable = lazy(() => import("../pages/EmployeeTable"));
const EnterpriseAdmin = lazy(() => import("../pages/EnterpriseAdmin"));
const EnterpriseImport = lazy(() => import("../pages/EnterpriseImport"));

export function AppRoutes() {
  const { user } = useAuth();

  const adminAcess = user?.acess_level === "0";
  const sellerAcess = adminAcess || user?.acess_level === "2";

  return (
    <Layout>
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route path="/entregar" exact component={Movement} />
          <Route
            path="/empresas/:enterprise_id/analise"
            exact
            component={AnalysisDates}
          />
          <Route
            path="/empresas/:enterprise_id/data/:start/:end"
            exact
            component={AnalysisData}
          />
          <Route
            path="/empresas/:enterprise_id/analise/:start/:end"
            exact
            component={Analysis}
          />

          {adminAcess && (
            <Route
              path="/empresas/:enterprise_id/editar"
              exact
              component={EnterpriseAdmin}
            />
          )}

          {sellerAcess && (
            <Route
              path="/empresas/:enterprise_id/importar"
              exact
              component={EnterpriseImport}
            />
          )}

          {sellerAcess && (
            <Route
              path="/empresas/:enterprise_id/novo/:start/:end"
              exact
              component={NewTicket}
            />
          )}

          {sellerAcess && (
            <Route
              path="/empresas/:enterprise_id/vales/:start/:end"
              exact
              component={Tickets}
            />
          )}

          {sellerAcess && (
            <Route
              path="/empresas/:enterprise_id/funcionarios/:employee_id"
              exact
              component={Employee}
            />
          )}

          {sellerAcess && (
            <Route
              path="/empresas/:enterprise_id/funcionarios"
              exact
              component={EmployeeTable}
            />
          )}

          <Route path="/" exact component={Enterprises} />
          <Route path="*" exact component={NotFound} />
        </Switch>
      </Suspense>
    </Layout>
  );
}
