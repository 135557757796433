import { ReactNode } from "react";
import { Box, Grid } from "@chakra-ui/react";
import { Navbar } from "../Navbar";

interface ILayoutProps {
  children: ReactNode;
}

export function Layout({ children }: ILayoutProps) {
  return (
    <Grid>
      <Navbar />
      <Box marginTop={90} overflow="auto">
        {children}
      </Box>
    </Grid>
  );
}
