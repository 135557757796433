import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Loading } from '../components/Loading';

import { Login } from '../pages/Login';

export function AuthRoutes() {
  return (
    <Switch>
      <Suspense fallback={<Loading />}>
        <Route path="/" component={Login} />
      </Suspense>
    </Switch>
  );
}
