import { FormEvent, useState } from "react";
import { Button, Circle, Input, Image, Box } from "@chakra-ui/react";

import { Container } from "./styles";
import { themes } from "../../constants/themes";
import { useAuth } from "../../hooks/auth";

import LogoPng from "../../assets/images/logo.png";

export function Login() {
  const { signIn } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  async function handleLogin(event: FormEvent) {
    event.preventDefault();
    await signIn(email, password);
  }

  return (
    <Container>
      <main>
        <Box
          bg="white"
          borderRadius={15}
          paddingX={3}
          paddingY={10}
          shadow="2xl"
        >
          <div className="main-content">
            <Circle size="80px" bg={themes.colors.primary}>
              <Image src={LogoPng} width={50} />
            </Circle>

            <h2>Login</h2>
            <p>Entre com o seu email e senha</p>
            <form onSubmit={handleLogin}>
              <Input
                type="text"
                placeholder="E-mail"
                onChange={(event) => setEmail(event.target.value.trim())}
                value={email}
                width={"100%"}
                height={45}
                background="white"
                fontSize={18}
                focusBorderColor="blackAlpha.400"
                borderColor="blackAlpha.300"
                shadow="base"
              />
              <Input
                type="password"
                placeholder="Senha"
                onChange={(event) => setPassword(event.target.value)}
                value={password}
                width={"100%"}
                height={45}
                background="white"
                fontSize={18}
                marginTop={3}
                focusBorderColor="blackAlpha.400"
                borderColor="blackAlpha.300"
                shadow="base"
              />
              <Button type="submit" colorScheme="green" size="md" marginTop={5}>
                Entrar
              </Button>
            </form>
          </div>
        </Box>
      </main>
    </Container>
  );
}
