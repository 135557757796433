import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-items: stretch;
    height: 100vh;

    main {
        flex: 8;

        padding: 0 32px;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    .main-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 320px;
        align-items: center;
        text-align: center;


        h2 {
            font-size: 24px;
            font-weight: 600;
            margin: 10px 0;
            font-family: 'Roboto', sans-serif;
            color: #191919;
        }

        p {
            font-size: 16px;
            color: #2C3333;
            margin-bottom: 10px;
        }
    }
`;
