import { Box, Text } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";

type LinkProps = {
  title: string;
  to: string;
  icon: any;
  exact?: boolean;
};

export function Link({ title, to, icon, exact }: LinkProps) {
  return (
    <Box
      as={NavLink}
      marginEnd={2}
      bg="green.600"
      position="relative"
      color="white"
      to={to}
      d="flex"
      borderRadius={5}
      h={58}
      paddingX={5}
      justifyContent="center"
      alignItems="center"
      exact={exact}
      zIndex={4}
      fontWeight="bold"
      _activeLink={{
        backgroundColor: "green.600",
        color: "white",
        _after: {
          content: "''",
          position: "absolute",
          bottom: 0,
          backgroundColor: "white",
          height: 1,
          width: "100%",
          borderTopRadius: 2,
        },
      }}
    >
      <Box as={icon} marginEnd={[0, 2]} />
      <Text d={["none", "block"]}>{title}</Text>
    </Box>
  );
}
