import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }

    html, body, #root {
      height: 100%;
      background: #eeee;
    }

    *, button, input {
      border: 0;
      outline: 0;
      font-family: 'Roboto', sans-serif;
    }

    button {
      cursor: pointer;
    }

    svg {
      font-size: 18px;
    }

    @media (max-width: 1080px) {
      html {
          font-size: 93.75%;
      }    
    }

    @media (max-width: 720px) {
        html {
            font-size: 87.5%;
        }    
    }



    // scrollbar
    table, ul, ol {
      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }
      &::-webkit-scrollbar-track {
        border-radius: 10px;
        background: rgba(0,0,0,0.1);
      }
      &::-webkit-scrollbar-thumb{
        border-radius: 10px;
        background: rgba(0,0,0,0.2);
      }
      &::-webkit-scrollbar-thumb:hover{
        background: rgba(0,0,0,0.4);
      }
      &::-webkit-scrollbar-thumb:active{
        background: rgba(0,0,0,.9);
      }
    }
`;
