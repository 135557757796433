import { Box, ScaleFade, Spinner, Text } from '@chakra-ui/react';

type LoadingProps = {
  message?: string;
}

export function Loading({ message }: LoadingProps) {
  return (
    <Box
      position="absolute"
      top={0}
      bottom={0}
      left={0}
      right={0}
      d="flex"
      justifyContent="center"
      alignItems="center"
      zIndex={4}
    >
      <Box
        position="absolute"
        top={0}
        bottom={0}
        left={0}
        right={0}
        width="100%"
        height="100%"
        bg="black"
        opacity={0.3}
        zIndex={5}
      />
      <Box d="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <ScaleFade initialScale={2} in={true}>
          <Spinner
            thickness='3px'
            color='green.600'
            speed='0.65s'
            size='xl'
            zIndex={6}
          />

        </ScaleFade>
        <Text fontWeight={500} fontSize={20}>{message}</Text>
      </Box>

    </Box>
  );
}
