import axios from "axios";

const { REACT_APP_API_URL } = process.env;

const api = axios.create({
  baseURL: REACT_APP_API_URL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("cestabasicabrasil@token")}`,
  },
});

api.interceptors.request.use(
  (res: any) => res,
  (err: any) => {
    if (err.response.status === 401) {
      localStorage.removeItem("cestabasicabrasil@token");
      window.location.reload();
    }
    throw err;
  },
);

// Add a request interceptor
api.interceptors.response.use(
  (res: any) => res,
  (err: any) => {
    if (err.response.status === 401) {
      localStorage.removeItem("cestabasicabrasil@token");
      window.location.reload();
    }
    throw err;
  },
);

export default api;
