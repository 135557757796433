import { Flex, Text } from "@chakra-ui/react";

export function NotFound() {
  return (
    <Flex
      bg="white"
      height="100%"
      top={0}
      right={0}
      left={0}
      position="absolute"
      justifyContent="center"
      alignItems="center"
    >
      <Text
        fontSize={24}
        fontWeight="bold"
        borderRightWidth={1}
        borderRightColor="blackAlpha.400"
        paddingRight={2}
        marginRight={2}
      >
        404
      </Text>
      <Text color="gray.700" fontSize={15}>
        Página não encontrada
      </Text>
    </Flex>
  );
}
