import { ImTicket } from "react-icons/im";
import { BiChevronDown } from "react-icons/bi";
import { MdBusinessCenter } from "react-icons/md";
import { Avatar, Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";

import { Container } from "./styles";
import { useAuth } from "../../hooks/auth";
import LogoPng from "../../assets/images/logo.png";

import { Link } from "./Link";

export function Navbar() {
  const { logout, user } = useAuth();

  const links = [
    {
      title: "Empresas",
      href: "/",
      acess_level: ["0", "1", "2", "3"],
      icon: MdBusinessCenter,
    },
    {
      title: "Entregar cesta",
      href: "/entregar",
      acess_level: ["0", "3"],
      icon: ImTicket,
    },
  ];

  return (
    <Container>
      <Box
        position="fixed"
        bg="green.600"
        zIndex={3}
        top={0}
        right={0}
        left={0}
        height={59}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        paddingX={[3, 10]}
        borderBottomWidth={0.4}
      >
        <Box flexDirection="row" d="flex" alignItems="center">
          <Box marginEnd={50} bg="green.600">
            <Image src={LogoPng} width={25} height={27} />
          </Box>
          {links.map((link, index) => {
            if (
              !!user?.acess_level &&
              link.acess_level.includes(user?.acess_level)
            ) {
              return (
                <Link
                  key={index}
                  icon={link.icon}
                  title={link.title}
                  exact
                  to={link.href}
                />
              );
            }

            return <></>;
          })}
        </Box>

        <Menu>
          <MenuButton
            as={Button}
            variant="unstyled"
            _focus={{
              borderColor: "green",
            }}
            rightIcon={<BiChevronDown color="white" />}
            d="flex"
            justifyContent="center"
            flexDirection="row"
          >
            <Flex justifyContent="center" alignItems="center" gap={2}>
              <Avatar name={user?.name} bg="blue.600" size="sm" color="white" />
            </Flex>
          </MenuButton>
          <MenuList
            justifyContent="center"
            alignItems="center"
            d="flex"
            flexDirection="column"
          >
            <Text fontSize={13} margin={2} fontWeight="bold" color="gray.700">
              {user?.name.toUpperCase()}
            </Text>
            <MenuItem
              onClick={() => logout()}
              margin={1}
              width="90%"
              borderRadius={5}
              bg="gray.100"
              _hover={{
                bg: "gray.200",
              }}
            >
              Sair
            </MenuItem>
          </MenuList>
        </Menu>
      </Box>
    </Container>
  );
}
