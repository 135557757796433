export const themes = {
  title: "light",

  colors: {
    primary: "#198754",
    secondary: "#fef00e",
    tertiary: "#F5F5F5",

    white: "#FFF",
    black: "#000",
    gray: "#f8f8f827",

    background: "#eeeeee",

    success: "#03BB85",
    info: "#3B5998",
    warning: "#FF5C58",
  },
};
