import { ToastContainer } from "react-toastify";

import GlobalStyles from "./styles/GlobalStyles";
import "react-toastify/dist/ReactToastify.css";

import { Routes } from "./routes";
import { AuthProvider } from "./hooks/auth";
import { ChakraProvider } from "@chakra-ui/react";

function App() {
  return (
    <>
      <ToastContainer />
      <GlobalStyles />
      <ChakraProvider>
        <AuthProvider>
          <Routes />
        </AuthProvider>
      </ChakraProvider>
    </>
  );
}

export { App };
